import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '',
                name: 'home',
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/home/index.vue"),
            },
            {
                path: '/intoBQ',
                name: 'intoBQ',
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/intoBQ/index.vue"),
            },
            {
                path: '/solution',
                name: 'solution',
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/solution/index.vue"),
            },
            {
                path: '/solution/1',
                name: 'solution1',
                meta: {parent: "/solution"},
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/solution/1.vue"),
            },
            {
                path: '/solution/2',
                name: 'solution2',
                meta: {parent: "/solution"},
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/solution/2.vue"),
            },
            {
                path: '/solution/3',
                name: 'solution3',
                meta: {parent: "/solution"},
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/solution/3.vue"),
            },

            {
                path: '/joinUs',
                name:'joinUs',
                redirect: "/joinUs/recruit",
                children: [
                ],
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/joinUs/index.vue"),

            },
            {
                path: '/joinUs/recruit',
                name: 'recruit',
                meta: {parent: "/joinUs"},
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/joinUs/recruitList.vue"),
            },
            {
                path: '/joinUs/recruitDetail',
                name: 'recruitDetail',
                meta: {parent: "/joinUs"},
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/joinUs/recruit.vue"),
            },
            {
                path: '/joinUs/business',
                name: 'business',
                meta: {parent: "/joinUs"},
                component: () =>
                    import(/* webpackChunkName: "home" */ "views/joinUs/business.vue"),
            },

        ]
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
