
import { Component, Vue, PropSync } from "vue-property-decorator";
import Header from "./header.vue";
import Footer from "./footer.vue";

@Component({
  components: {
    Header,
    Footer
  }
})
export default class Layout extends Vue {}
