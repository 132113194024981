
import {Vue, Component} from "vue-property-decorator";
import basicInfo from "untils/basicInfo";

@Component({
  name: "home_foot"
})
export default class FooterView extends Vue {
  basicInfo = basicInfo;
  footOptions = [
    {
      menuTitle: "关于我们",
      menus: [
        { title: "公司简介", path: "/intoBQ"},
        { title: "本千文化", path: "/intoBQ"},
        { title: "本千团队", path: "/intoBQ"},
      ]
    },
    {
      menuTitle: "加入我们",
      menus: [
        { title: "商务合作", path: "/joinUs/business"},
        { title: "招贤纳士", path: "/joinUs/recruit"},
      ]
    },
    {
      menuTitle: "友情链接",
      menus: [
        { title: "四川大学华西医院", path: "https://www.wchscu.cn/index.html"},
        { title: "四川省人民医院", path: "https://www.samsph.com/"},
      ]
    }
  ];
  tapJump(path: string){
    if (path) {
      if (path.includes("https://www")) {
        window.open(path);
      } else {
        if (window.location.href.includes(path)) {
          // 如果就处于即将跳转的页面，则回到页面顶部
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        } else {
          this.$router.push(path);
        }
      }
    }
  }
}

