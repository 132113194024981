
import { Vue, Component } from "vue-property-decorator";

interface IMenu {
  title: string;
  path: string;
  children?: IMenu[];
}

@Component({
  name: "home_head"
})
export default class HeaderView extends Vue {
  disabledsub: boolean = false;
  showMenu: boolean = false;
  menuData: IMenu[] = [
    {
      title: "首页",
      path: "/"
    },
    {
      title: "走进本千",
      path: "/intoBQ"
    },
    {
      title: "解决方案与产品",
      path: "/solution",
      children: [
        {
          title: "互联网+医疗",
          path: "/solution/1"
        },
        {
          title: "区域医疗与数据共享",
          path: "/solution/2"
        },
        {
          title: "院内信息化解决方案",
          path: "/solution/3"
        }
      ]
    },
    {
      title: "加入我们",
      path: "/joinUs",
      children: [
        {
          title: "招贤纳士",
          path: "/joinUs/recruit"
        },
        {
          title: "商务合作",
          path: "/joinUs/business"
        }
      ]
    }
  ];

  // 获取当前设备的宽度
  screenWidth = window.screen.width;

  created() {}

  toHome() {
    this.$router.push({ name: "home" });
  }

  touch(show: boolean) {
    // 临时禁用子菜单，达到点击后消息的效果
    this.disabledsub = show;
  }

  itemClick(item: IMenu) {
    this.$router.push(item.path);
    // 临时禁用子菜单，达到点击后消息的效果
    this.disabledsub = true;
    this.showMenu = false;
    setTimeout(() => {
      this.disabledsub = false;
    }, 300)
  }
}
