
import { Component, Vue,Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
    @Watch('$route')
    changeRoute(newVal:any) {
        // console.log('@newVal',newVal);
        window.scrollTo(0,0);
    }
}
