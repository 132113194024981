declare global {
    interface Window {
        _address: string;
        _detailAddress: string;
        _wordAddress: string;
        _versionNumber: string;
        _tel: string;
        _email: string;
        _BANumberUrl: string;
        _BANumber: string;
    }
}
export default {
    address: window._address||'成都市高新区世纪城南路599号',
    detailAddress: window._detailAddress||'天府软件园D区5座904',
    wordAddress: window._wordAddress||'成都市高新区天府软件园D区5座904',
    versionNumber: window._versionNumber||'2019-2020',
    tel: window._tel||'+028-61674043',
    BANumberUrl: window._BANumberUrl||'https://beian.miit.gov.cn',
    BANumber: window._BANumber||'蜀ICP备20024076号',
    email: window._email||'admin@bq-health.com',
    mapUrl: require("assets/images/joinUs/map2.png")
}
